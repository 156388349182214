import React from 'react';

const EnvironmentIndicator = () => {
  const gitSha = process.env.REACT_APP_GIT_SHA || '';
  
  return (
    <div 
      style={{
        position: 'fixed',
        bottom: '8px',
        left: '8px',
        padding: '4px 8px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        color: '#cccccc',
        fontSize: '12px',
        borderRadius: '4px',
        zIndex: 9999
      }}
    >
      {process.env.REACT_APP_NODE_ENV} {gitSha}
    </div>
  );
};

export default EnvironmentIndicator; 