import { ApolloClient, from } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import jwtService from "../originServices/jwtService";
import { ApolloLink } from "@apollo/client/core";

// Set BC Apollo Server link
const uri = process.env.REACT_APP_GRAPHQL_URL;
// const wsUri = process.env.REACT_APP_GRAPHQL_WS;
console.log("Environnement process.env.REACT_APP_GRAPHQL_URL :",process.env.REACT_APP_GRAPHQL_URL);
console.log("Environnement React :");
Object.keys(process.env)
  .forEach((key) => {
    console.log(`Environnement ${key}: ${process.env[key]}`);
  });
console.log("Environnement REACT_APP_NODE_ENV:", process.env.REACT_APP_NODE_ENV);
// Set the Apollo Server http link
const httpLink = createUploadLink({ uri });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => {
    const authHeaders = jwtService.getAuthHeaders();
    if (authHeaders) {
      headers = { headers, ...authHeaders };
    }
    return { headers };
  });

  return forward(operation);
});

const errorMiddleware = onError(
  ({ response, graphQLErrors, networkError }) => {
    // If the error comes from the graphQL
    if (Array.isArray(graphQLErrors)) {
      const grantError = graphQLErrors.find((error) => {
        return error.extensions.code.toUpperCase() === "GRANT_ERROR";
      });
      if (grantError) {
        jwtService.emit("onAutoLogout", grantError.message);
        jwtService.setSession(null);
        client?.resetStore();
        response.errors = null;
      }
    }
    if (networkError) {
      // Log
    }
  }
);

// Creat the Apollo Client
const client = new ApolloClient({
  link: from([authMiddleware, errorMiddleware, httpLink]),
  cache: new InMemoryCache({
    addTypename: true,
    possibleTypes: {
      AsyncDataNode: ["Activity"],
      ActivityFilterData: [
        "ActivityFilterEmployee",
        "ActivityFilterFolder",
        "ActivityFilterComment",
        "ActivityFilterInsurer",
        "ActivityFilterType",
        "ActivityFilterCategory",
        "ActivityFilterCustomer",
      ],
    },
  }),
});

export default client;
